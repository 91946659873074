<template>
	<div class="header">
		<!-- 折叠按钮 -->
		<div class="collapse-btn" @click="collapseChage()">
			<i v-if="!collapse" class="el-icon-s-fold"></i>
			<i v-else class="el-icon-s-unfold"></i>
		</div>
		<div class="logo">校园网络管理系统</div>
		<div class="header-right">
			<div class="header-user-con">
				<!-- 消息中心 -->
				<!-- <div class="btn-bell">
                            <el-tooltip
                                effect="dark"
                                :content="message?`有${message}条未读消息`:`消息中心`"
                                placement="bottom"
                            >
                                <router-link to="/tabs">
                                    <i class="el-icon-bell"></i>
                                </router-link>
                            </el-tooltip>
                            <span class="btn-bell-badge" v-if="message"></span>
                        </div> -->
				<!-- 用户头像 -->
				<!-- <div class="user-avator">
                    <img src="../assets/img/img.jpg" />
                </div> -->
				<!-- 用户名下拉菜单 -->
				<el-dropdown class="user-name" trigger="click" @command="handleCommand">
					<span class="el-dropdown-link">
						{{ username }}
						<i class="el-icon-caret-bottom"></i>
					</span>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item @click="handleForm(null, null)">修改密码</el-dropdown-item>
							<el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
		</div>
	</div>
	<!-- 隐藏图层 -->
	<el-dialog v-model="formVisible" :before-close="hideForm" width="85%" top="5vh">
		<el-form :model="formData" :rules="formRules" ref="dataForm">
			<el-form-item label="修改密码" prop="password" v-if="formName == 'add'">
				<el-input type="password" v-model="formData.password" auto-complete="off"></el-input>
			</el-form-item>
			<el-form-item label="确认密码" prop="password_confirm" v-if="formName == 'add'">
				<el-input type="password" v-model="formData.password_confirm" auto-complete="off"></el-input>
			</el-form-item>
		</el-form>
		<div class="dialog-footer">
			<el-button @click="hideForm()">取消</el-button>
			<el-button type="primary" @click="formSubmit()" :loading="formLoading">提交
			</el-button>
		</div>
	</el-dialog>
</template>
<script>
	import {
		usersList,
		passwordSave,
	} from "../api/users";
	const formJson = {};
	export default {
		data() {
			let validatePass = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请输入密码"));
				} else {
					callback();
				}
			};
			let validatePass2 = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请再次输入密码"));
				} else if (value !== this.formData.password) {
					callback(new Error("两次输入密码不一致!"));
				} else {
					callback();
				}
			};
			return {
				fullscreen: false,
				name: "",
				message: 2,
				formLoading: false,
				formVisible: false,
				formData: formJson,
				addRules: {
					password: [{
							required: true,
							message: "请输入密码",
							trigger: "blur"
						},
						{
							validator: validatePass,
							trigger: "blur"
						},
					],
					password_confirm: [{
							required: true,
							message: "请再次输入密码",
							trigger: "blur",
						},
						{
							validator: validatePass2,
							trigger: "blur"
						},
					],
				},
			};
		},
		computed: {
			username() {
				let username = localStorage.getItem("u_name");
				return username ? username : this.name;
			},
			rolename() {
				let rolename = localStorage.getItem("role_name");
				return rolename ? rolename : this.role_name;
			},

			collapse() {
				return this.$store.state.collapse;
			},
		},
		methods: {
			// 用户名下拉菜单选择事件
			handleCommand(command) {
				if (command == "loginout") {
					localStorage.removeItem("u_name");
					// localStorage.removeItem("role_name");
					localStorage.removeItem("token");
					localStorage.removeItem("myMenu");
					this.$router.push("/login");
				}
			},
			// 侧边栏折叠
			collapseChage() {
				this.$store.commit("hadndleCollapse", !this.collapse);
			},
			getList() {
				this.loading = true;
				usersList(this.query)
					.then((res) => {
						this.loading = false;
						this.list = res.data.data || [];
					})
					.catch(() => {
						this.loading = false;
						this.list = [];
					});
			},
			// 隐藏表单
			hideForm() {
				this.formVisible = !this.formVisible;
				this.$refs["dataForm"].resetFields();
				return true;
			},
			// 显示表单
			handleForm(index, row) {
				this.formVisible = true;
				//   console.log(row);
				this.formData = JSON.parse(JSON.stringify(formJson));
				if (row !== null) {
					this.formData = Object.assign({}, row);
				}
				this.formName = "add";
				this.formRules = this.addRules;
				if (index !== null) {
					this.index = index;
					this.formName = "edit";
					this.formRules = this.editRules;
				}
			},
			formSubmit() {
				this.$refs["dataForm"].validate((valid) => {
					if (valid) {
						this.formLoading = true;
						let data = Object.assign({}, this.formData);
						// let data2 = {
						// 	password: this.formData.password
						// };
						passwordSave(data).then((response) => {
							this.formLoading = false;
							if (response.code != 200) {
								this.$message.error(response.msg);
								return false;
							}
							this.$message.success("操作成功");
							this.formVisible = false;
							this.getList();
						});
					}
				});
			},
		},
		mounted() {
			if (document.body.clientWidth < 1500) {
				this.collapseChage();
			}
		},
	};
</script>
<style scoped>
	.header {
		position: relative;
		box-sizing: border-box;
		width: 100%;
		height: 70px;
		font-size: 22px;
		color: #fff;
	}

	.collapse-btn {
		float: left;
		padding: 0 21px;
		cursor: pointer;
		line-height: 70px;
	}

	.header .logo {
		float: left;
		width: 220px;
		line-height: 70px;
	}

	.header-right {
		float: right;
		padding-right: 50px;
	}

	.header-user-con {
		display: flex;
		height: 70px;
		align-items: center;
	}

	.btn-fullscreen {
		transform: rotate(45deg);
		margin-right: 5px;
		font-size: 24px;
	}

	.btn-bell,
	.btn-fullscreen {
		position: relative;
		width: 30px;
		height: 30px;
		text-align: center;
		border-radius: 15px;
		cursor: pointer;
	}

	.btn-bell-badge {
		position: absolute;
		right: 0;
		top: -2px;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		background: #f56c6c;
		color: #fff;
	}

	.btn-bell .el-icon-bell {
		color: #fff;
	}

	.user-name {
		margin-left: 10px;
	}

	.user-avator {
		margin-left: 20px;
	}

	.user-avator img {
		display: block;
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}

	.el-dropdown-link {
		color: #fff;
		cursor: pointer;
	}

	.el-dropdown-menu__item {
		text-align: center;
	}
</style>
