import request from '../utils/request';

export function usersList(query) {
    return request({
        url: "users",
        method: "get",
        params: query
    });
}

export function useradminSave(data, formName) {
    if (formName == "add") {
        return request({
            url: "users",
            method: "post",
            data: data
        });
    } else {
        var itemId=data.id;
        // console.log(data)
        delete data.id;
        return request({
            url: "users/"+itemId,
            method: "put",
            data: data
        });
    }
}

export function useradminDelete(query) {
    return request({
        url: 'users/'+query,
        method: 'delete',
        // data: query
    });
}
export function useradminActive(query) {
    return request({
        url: 'users/'+query,
        method: 'PATCH',
        // data: query
    });
}

// 修改密码
export function passwordSave(data) {
	return request({
		url: "set_pwd",
		method: "POST",
		data: data
	});
}