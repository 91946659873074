import {
	createRouter,
	createWebHistory
} from "vue-router";
import Home from "../views/Home.vue";

const routes = [{
	path: '/',
	redirect: '/whitelist'
}, {
	path: "/",
	name: "Home",
	component: Home,
	children: [
		// {
		// 	path: "/homepage",
		// 	name: "homepage",
		// 	meta: {
		// 		title: '中间件日志'
		// 	},
		// 	component: () => import(
		// 		/* webpackChunkName: "homepage" */
		// 		"../views/homepage.vue")
		// },
		{
			path: "/whitelist",
			name: "whitelist",
			meta: {
				title: '学生网络白名单'
			},
			component: () => import(
				"../views/whitelist.vue")
		},
		{
			path: "/teacherlist",
			name: "teacherlist",
			meta: {
				title: '教师网络白名单'
			},
			component: () => import(
				"../views/teacherlist.vue")
		},
		{
			path: "/sys_config",
			name: "sys_config",
			meta: {
				title: '无线网络设置'
			},
			component: () => import(
				"../views/sys_config.vue")
		},
		{
			path: "/users",
			name: "users",
			meta: {
				title: '用户管理'
			},
			component: () => import(
				"../views/users.vue")
		},
		{
			path: "/accountlist",
			name: "accountlist",
			meta: {
				title: '网络白名单-账号管理'
			},
			component: () => import(
				"../views/accountlist.vue")
		},
		{
			path: "/whiterecord",
			name: "whiterecord",
			meta: {
				title: '学生网络白名单-导入记录'
			},
			component: () => import(
				"../views/whiterecord.vue")
		},
		{
			path: "/teacherrecord",
			name: "teacherrecord",
			meta: {
				title: '教师网络白名单-导入记录'
			},
			component: () => import(
				"../views/teacherrecord.vue")
		},
		{
			path: '/404',
			name: '404',
			meta: {
				title: '找不到页面'
			},
			component: () => import(
				'../views/404.vue')
		},
	]
}, {
	path: "/login",
	name: "Login",
	meta: {
		title: '登录'
	},
	component: () => import(
		/* webpackChunkName: "login" */
		"../views/Login.vue")
}];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	document.title = `${to.meta.title} | 校园网络管理系统`;
	const role = localStorage.getItem('u_name');
	// console.log(to.meta)
	if (!role && to.path !== '/login' && !localStorage.getItem("token")) {
		next('/login');
	} else if (to.meta.permission) {
		// 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
		role === 'admin' ?
			next() :
			next('/403');
	} else {
		next();
	}
});

export default router;
