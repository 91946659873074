<template>
	<div class="sidebar">
		<el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#1f3a63"
			text-color="#fff" active-text-color="#5eadff" unique-opened router>
			<template v-for="item in items">
				<template v-if="item.subs">
					<el-submenu :index="item.index" :key="item.index">
						<template #title>
							<i :class="item.icon"></i>
							<span>{{ item.title }}</span>
						</template>
						<template v-for="subItem in item.subs">
							<el-submenu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
								<template #title>{{ subItem.title }}</template>
								<el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">
									{{ threeItem.title }}</el-menu-item>
							</el-submenu>
							<el-menu-item v-else :index="subItem.index" :key="subItem.index">{{ subItem.title }}
							</el-menu-item>
						</template>
					</el-submenu>
				</template>
				<template v-else>
					<el-menu-item :index="item.index" :key="item.index">
						<i :class="item.icon"></i>
						<template #title>{{ item.title }}</template>
					</el-menu-item>
				</template>
			</template>
		</el-menu>
	</div>
</template>

<script>
	// import bus from "../common/bus";
	export default {
		data() {
			return {
				items: [
					// 管理员
					// {
					// 	icon: "el-icon-s-home",
					// 	index: "homepage",
					// 	title: "首页"
					// },
					{
						icon: "el-icon-lx-group",
						index: "whitelist",
						title: "学生网络白名单"
					},
					{
						icon: "el-icon-lx-group",
						index: "teacherlist",
						title: "教师网络白名单"
					},
					{
						icon: "el-icon-alarm-clock",
						index: "sys_config",
						title: "无线网络设置"
					},
					{
						icon: "el-icon-setting",
						index: "users",
						title: "用户管理"
					},
				]
			};
		},
		computed: {
			onRoutes() {
				return this.$route.path.replace("/", "");
			},
			collapse() {
				return this.$store.state.collapse
			}
		},
		created() {
			const routeArr = JSON.parse(localStorage.getItem('menudata'));
			this.navList = routeArr;
		}
	};
</script>

<style scoped>
	.sidebar {
		display: block;
		position: absolute;
		left: 0;
		top: 70px;
		bottom: 0;
		overflow-y: scroll;
	}

	.sidebar::-webkit-scrollbar {
		width: 0;
	}

	.sidebar-el-menu:not(.el-menu--collapse) {
		width: 220px;
	}

	.sidebar>ul {
		height: 100%;
	}
</style>
