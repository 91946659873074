import axios from 'axios';
import router from '@/router'

const service = axios.create({
	// process.env.NODE_ENV === 'development' 来判断是否开发环境
	// baseURL: 'http://42.180.48.31:6580/api/v1/',
	baseURL: process.env.VUE_APP_SERVICE_URL + '/api/v1/',
	// timeout: 5000
});

service.interceptors.request.use(
	config => {
		let token = localStorage.getItem("token");
		// console.log(token)
		if (token) {
			config.headers["Authorization"] = "Bearer " + token;
		}
		return config;
	},
	error => {
		console.log(error);
		return Promise.reject();
	}
);

service.interceptors.response.use(
	response => {
		// console.log(response)
		if (response.status === 200) {
			// console.log(response.data.code)
			// console.log(response.data.msg)
			if (response.data.code == 400 && response.data.msg =='token异常') {
				alert('登录超时，请重新登录！')
				// console.log(response.data.msg)
				router.push('/login');
			} else {
				return response.data;
			}
		} else {
			Promise.reject();
		}
	},
	error => {
		console.log(error);
		return Promise.reject();
	}
);

export default service;
